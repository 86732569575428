import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HomeIN = () => {
  const [product, setProduct] = useState({
    name: '',
    additional:'',
    description: '',
    id:'',
    category: '',
    star: '',
    price: '',
    image: null
  });

   const [authorization,SetAuthorization]=useState('');


  useEffect(() => {

let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}
if(validate){
  SetAuthorization(validate);
}



    const productToEdit = JSON.parse(sessionStorage.getItem("productToEdit"));
    if (productToEdit) {
      setProduct({
        name: productToEdit.title,
        id:productToEdit.id,
        additional:productToEdit.additional,
        description: productToEdit.description,
        category: productToEdit.category,
        star: productToEdit.star,
        price: productToEdit.price,
        image: null 
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    setProduct(prevState => ({
      ...prevState,
      image: e.target.files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('title', product.name);
      formData.append('id',product.id);
      formData.append('additional',product.additional);
      formData.append('description', product.description);
      formData.append('category', product.category);
      formData.append('star', product.star);
      formData.append('price', product.price);
      if (product.image) {
        formData.append('image', product.image);
      }

      const response = await axios.put('https://abz.zuluevconsultancy.com/admin/updateProduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
           "authorization":authorization,
        }
      });

      console.log('Product updated successfully:', response.data);
     
      window.location.href="/Home"
    } catch (error) {
      alert("not update")
      console.error('Error updating product:', error);
     
    }
  };

  return (
    <div>
      <h2 className='w-auto text-center font-bold text-xl mb-8'>Upload Products</h2>
      <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Name:</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Description:</label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          ></textarea>
        </div>

{/*  */}
<div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Additional Details:</label>
          <textarea
            name="additional"
            value={product.additional}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          ></textarea>
        </div>

{/*  */}

        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Category:</label>
          <input
            type="text"
            name="category"
            value={product.category}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        {/* <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Star:</label>
          <input
            type="text"
            name="star"
            value={product.star}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div> */}
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Price:</label>
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Image:</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg ml-[45%]">
          Update Product
        </button>
      </form>
    </div>
  );
};

export default HomeIN;
