import React, { useEffect, useState } from 'react';

const HomeIN = () => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    category:'',
    star:'',
    price: '',
    additional:'',
  });


  const [authorization,SetAuthorization]=useState('');


useEffect(()=>{
let validate = sessionStorage.getItem('token');
// if(!validate){
//   window.location.href="/";
// }
if(validate){
  SetAuthorization(validate);
}

},[]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setProduct({
      ...product,
      image: e.target.files[0],
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('additional',product.additional);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('image', product.image);
    formData.append('category',product.category);
    formData.append('star',5);

    console.log('Product uploaded', product);

     try {
      const apiUrl = `https://abz.zuluevconsultancy.com/admin/addProduct`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers:{
          "authorization":authorization,
        },
        body: formData, 
      });

      if (!response.ok) {
        throw new Error('Failed to add product');
      }
      const data = await response.json();
      alert('Product added successfully');
      window.location.reload();
      console.log('Product added successfully:', data);
    } catch (error) {
      alert('Error adding product');
      console.error('Error adding product:', error);
    }
  };

  return (
    <div>

        <h2 className='w-auto text-center font-bold text-xl mb-8'>
Upload Products
        </h2>
      <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Name:</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Description:</label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          ></textarea>
        </div>


         <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Additional Details:</label>
          <textarea
            name="additional"
            value={product.additional}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          ></textarea>
        </div>


{/*  */}

<div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Category:</label>
          <input
            type="text"
            name="category"
            value={product.category}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>



        


<div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Star:</label>
          <input
            type="text"
            name="star"
            value={product.star}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>

{/*  */}

        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Price:</label>
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Image:</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg ml-[45%]">
          Upload Product
        </button>
      </form>
    </div>
  );
};

export default HomeIN;
