import { ChevronFirst, MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [activeMenuItem, setActiveMenuItem] = useState('');

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    return (
        <aside className={`h-screen flex flex-col border-r bg-black shadow-sm ${isMenuOpen ? 'w-45 max-sm:w-36' : 'w-12'}  `}>
            <div className="p-4 pb-2 flex justify-between items-center">
                <img src="https://img.logoipsum.com/243.svg" alt="Logo" className={`transition-all duration-300 ${isMenuOpen ? 'w-40' : 'w-0'}`} />
                <button
                    onClick={handleToggleMenu}
                    className="p-0 ml-auto rounded-lg bg-gray-50 hover:bg-gray-100"
                >
                    <ChevronFirst className={`w-10 h-8 transition-transform duration-300 ${isMenuOpen ? '' : 'transform rotate-180'}`} />
                </button>
            </div>

            <ul className="flex-1 bg-white px-3">
                <li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'home' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('home')}>
                    <Link to="/home" className="flex items-center">
                        <svg className={`w-8 h-5 mr-2 ${activeMenuItem === 'home' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                            <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                            <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                        </svg>
                        {isMenuOpen && 'Dashboard'}
                    </Link>
                </li>




                <li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'orders' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('orders')}>
                    <Link to="/orders" className="flex items-center">
                        <svg className={`w-8 h-7 mr-2 ${activeMenuItem === 'orders' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
                        </svg>
                        {isMenuOpen && 'Orders'}
                    </Link>
                </li>

                {/* <li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'setting' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('setting')}>
                    <Link to="/setting" className="flex items-center">
                        <svg className={`w-8 h-7 mr-2 ${activeMenuItem === 'setting' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                        </svg>
                        {isMenuOpen && 'Setting'}
                    </Link>
                </li> */}



            {/* Drink Sales  */}
 <li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'DrinkSale' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('DrinkSale')}>
                    <Link to="/DrinkSale" className="flex items-center">
                       <svg className={`w-8 h-7 mr-2 ${activeMenuItem === 'DrinkSale' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
  <path d="M7 2C6.448 2 6 2.448 6 3v2H4c-1.104 0-2 .896-2 2v2h20V7c0-1.104-.896-2-2-2h-2V3c0-.552-.448-1-1-1H7zm0 2h10v2H7V4zM2 10v11c0 1.104.896 2 2 2h4c.552 0 1-.448 1-1s-.448-1-1-1H4V10H2zm16 0v11h-4c-.552 0-1 .448-1 1s.448 1 1 1h4c1.104 0 2-.896 2-2V10h-2zm-7 1v10c0 1.104.896 2 2 2h1c1.104 0 2-.896 2-2V11h-5zm2 0h1v10h-1V11z"/>
</svg>


                        {isMenuOpen && 'DrinkSale'}
                    </Link>
                </li>

{/*  */}

{/* Sales */}

<li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'Sales' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('Sales')}>
                    <Link to="/Sales" className="flex items-center">
                        <svg className={`w-8 h-7 mr-2 ${activeMenuItem === 'Sales' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                        </svg>
                        {isMenuOpen && 'Sales'}
                    </Link>
                </li>
{/*  */}


            </ul>




            <div className={`border-t flex p-3 transition-all duration-300 ${activeMenuItem ? 'block' : 'block'}`}>
                               <li className={`py-2 cursor-pointer flex items-center ${activeMenuItem === 'signout' ? 'text-blue-600 font-semibold' : 'text-gray-500'}`} onClick={() => handleMenuItemClick('signout')}>
                    <button onClick={()=>{
                        
                        sessionStorage.clear();
                        window.location.reload();
                    }} className="flex items-center">
                        <svg className={`w-8 h-5 mr-2 ${activeMenuItem === 'signout' ? 'text-blue-600' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                        </svg>
                        {isMenuOpen && 'Sign Out'}
                    </button>
                </li>
            </div>
        </aside>
    );
};

export default SideBar;
