import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditImages = () => {
  const [product, setProduct] = useState({
    name: '',
    additional: '',
    description: '',
    id: '',
    category: '',
    star: '',
    price: '',
    image1: null,
    image2: null,
    image3: null,
    image4: null
  });
  const [images, setImages] = useState([]);
  const [authorization, setAuthorization] = useState('');

  useEffect(() => {
    let validate = sessionStorage.getItem('token');
    if (!validate) {
      window.location.href = "/";
    }
    if (validate) {
      setAuthorization(validate);
    }

    const productToEdit = JSON.parse(sessionStorage.getItem("productToEdit"));
    if (productToEdit) {
      setProduct({
        name: productToEdit.title,
        id: productToEdit.id,
        additional: productToEdit.additional,
        description: productToEdit.description,
        category: productToEdit.category,
        star: productToEdit.star,
        price: productToEdit.price,
        image1: null,
        image2: null,
        image3: null,
        image4: null
      });
    }
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`https://abz.zuluevconsultancy.com/api/user/getImage?id=${product.id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (product.id) {
      fetchImages();
    }
  }, [product.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    setProduct(prevState => ({
      ...prevState,
      [`image${index}`]: file
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('id', product.id);
      
      for (let i = 1; i <= 4; i++) {
        const key = `image${i}`;
        if (product[key]) {
          formData.append(key, product[key]);
        }
      }

      const response = await axios.put('https://abz.zuluevconsultancy.com/admin/updateProductImage ', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': authorization,
        }
      });

      console.log('Product updated successfully:', response.data);
      window.location.href = "/Home";
    } catch (error) {
      alert("Product update failed");
      console.error('Error updating product:', error);
    }
  };

  return (
    <div>
      <h2 className='w-auto text-center font-bold text-xl mb-8'>Upload Products</h2>
      <h2>Product Name:</h2>
      <h3 className='w-1/4 text-xl font-bold text-gray-900 dark:text-black'>
        {product.name}
      </h3>
      <br />

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {  images.map((item, index) => (
          <div key={index} className="relative group">
            {item.image ?( <img className=" h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105" src={`data:image/png;base64,${item.image}`} alt="" /> ):<img
          className="h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105"
          src="path_to_empty_image_placeholder"
          alt="Empty"
        /> }
          </div>
        ))}

 {  images.map((item, index) => (
          <div key={index} className="relative group">
            {item.image1 ?( <img className=" h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105" src={`data:image/png;base64,${item.image1}`} alt="" /> ):<img
          className="h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105"
          src="path_to_empty_image_placeholder"
          alt="Empty"
        /> }
          </div>
        ))}

         {  images.map((item, index) => (
          <div key={index} className="relative group">
            {item.image2 ?( <img className=" h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105" src={`data:image/png;base64,${item.image2}`} alt="" /> ):<img
          className="h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105"
          src="path_to_empty_image_placeholder"
          alt="Empty"
        /> }
          </div>
        ))}

         {  images.map((item, index) => (
          <div key={index} className="relative group">
            {item.image3 ?( <img className=" h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105" src={`data:image/png;base64,${item.image3}`} alt="" /> ):<img
          className="h-64 w-full object-cover rounded-lg transition duration-300 transform group-hover:scale-105"
          src="path_to_empty_image_placeholder"
          alt="Empty"
        /> }
          </div>
        ))}



      
      </div>
      
      <div className="grid grid-cols-2 mt-4 md:grid-cols-4 gap-4">
        {[1, 2, 3, 4].map(index => (
          <div key={index}>
            <input
              type="file"
              onChange={(e) => handleImageChange(e, index)}
              className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
            />
          </div>
        ))}
      </div>

    

      <form onSubmit={handleSubmit} className="w-1/2  mt-8 ">
        <button type="submit" className="px-4 py-2 w-full bg-black text-white rounded-lg ml-[45%]">
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditImages;
