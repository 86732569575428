import React, { useEffect, useState } from 'react';

const AddSale = () => {
  const [product, setProduct] = useState({
    name: '',
    title:'',
    price: '',
    image: null,
  });
  const [authorization,SetAuthorization]=useState('');


  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setProduct({
      ...product,
      image: e.target.files[0],
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('title',product.title);
  
    formData.append('price', product.price);
    formData.append('image', product.image);

    // For demonstration, log the product data to console
    console.log('Product uploaded', product);

    
  try {
      const apiUrl = `https://abz.zuluevconsultancy.com/admin/AddSaleName`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers:{
          "authorization":authorization
        },
        body: formData, // Send the FormData object directly
      });

      if (!response.ok) {
        throw new Error('Failed to add product');
      }
      const data = await response.json();
      alert('Product added successfully');
      console.log('Product added successfully:', data);
    } catch (error) {
      alert('Error adding product');
      console.error('Error adding product:', error);
    }


  };

  useEffect(()=>{
    let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}
if(validate){
  SetAuthorization(validate);
}
  },[]);

  return (
    <div>

        <h2 className='w-auto text-center font-bold text-xl mb-8'>
Add New Drink Sale
        </h2>
      <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Name:</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>


{/* Title */}
<div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Title:</label>
          <input
            type="text"
            name="title"
            value={product.title}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>








       
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Special:</label>
          <input
            type="text"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="w-1/4 text-sm font-medium text-gray-900 dark:text-black">Image:</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-3/4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          />
        </div>
        <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg ml-[45%]">
          Upload 
        </button>
      </form>
    </div>
  );
};

export default AddSale;
