import React, { useState, useEffect } from 'react';

const OrderDetails = () => {
    const [orders, setOrders] = useState([]);
     const [authorization,SetAuthorization]=useState('');

    useEffect(() => {

let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}

if(validate){
  SetAuthorization(validate);
}


       const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
        fetch(`https://abz.zuluevconsultancy.com/admin/getOrder_items?id=${id}`) // Replace with your actual endpoint
            .then(response => response.json())
            .then(data => {
                console.log('Orders data:', data);
                setOrders(data); // Update orders state with fetched data
            })
            .catch(error => console.error('Error fetching orders:', error));
    }, []); // Empty dependency array ensures useEffect runs only once (on component mount)

    return (
        <div className="max-w-4xl mx-auto p-4">
            {orders.map(order => (
                <div key={order.order_id} className="bg-white rounded-lg shadow-lg mb-4 p-4">
                    <h3 className="text-lg font-semibold mb-2">Order ID: {order.order_id}</h3>
                    <p className="text-sm mb-2">User ID: {order.user_id}</p>
                    <p className="text-sm mb-2">Created Date: {order.created_date}</p>
                    <p className="text-sm mb-2">Created Time: {order.created_time}</p>
                    <div className="mt-2">
                        <h4 className="text-md font-semibold mb-2">Items:</h4>
                        <ul>
                            {order.items.map(item => (
                                <li key={item.item_id} className="text-sm mb-1">
                                    Item ID: {item.item_id}, 
                                    Product ID: {item.product_id}, 
                                    Quantity: {item.quantity}, 
                                    Price: {item.price}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderDetails;
