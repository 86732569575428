import React, { useState, useEffect } from "react";


const AddInDrink=()=>{

  const [products, setProducts] = useState([]);
  const [authorization,SetAuthorization]=useState('');


  useEffect(() => {

let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}
if(validate){
  SetAuthorization(validate);
}


    const fetchData = async () => {
      try {
        const response = await fetch("https://abz.zuluevconsultancy.com/api/user/");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
        console.log(data); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 


const Add = async (id) => {
     const params = new URLSearchParams(window.location.search);
    const drinkId = params.get("drinkId");

    //(drinkId);
    try {
      const apiUrl = `https://abz.zuluevconsultancy.com/admin/AddInDrink`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization":authorization,
        },
        body: JSON.stringify({ productId:id,drinkId }),
      });

      if (!response.ok) {
        throw new Error("Failed to add product");
      }
      const data = await response.json();
      alert("Product added successfully")
      console.log("Product added successfully:", data);
    } catch (error) {
      alert("Error adding product");
      console.error("Error adding product:", error);
      }
  };

    return(
        <>


<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Image
                </th>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Color
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td>
                    <img
                   src={`data:image/png;base64,${product.image}`}
                      alt=""
                      className="h-12 w-12 object-cover"
                    />
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {product.title}
                  </td>
                  <td className="px-6 py-4">{product.color}</td>
                  <td className="px-6 py-4">{product.category}</td>
                  <td className="px-6 py-4">${product.price}</td>
                  <td className="px-6 py-4 text-right">
                    <button
                    onClick={()=>{Add(product.id)}}
                      
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Add
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        </>
    )
}

export default AddInDrink;