import React, { useState, useEffect } from "react";

const Orders = () => {
  const [products, setProducts] = useState([]);
   const [authorization,SetAuthorization]=useState('');


  useEffect(() => {

let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}
if(validate){
  SetAuthorization(validate);
}


    const fetchData = async () => {
      try {
        const response = await fetch("https://abz.zuluevconsultancy.com/admin/getOrders");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
        console.log(data); // Check fetched data in console
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once on component mount

  const handleEdit = (product) => {
    sessionStorage.setItem("productToEdit", JSON.stringify(product));
    window.location.href = "/editProduct";
  };

  const deleteProduct = async (id) => {
   


    try {
      const apiUrl = `https://abz.zuluevconsultancy.com/admin/removeProduct/${id}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
           "authorization":authorization,
        },
        body: JSON.stringify({ id }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete product");
      }
      const data = await response.json();
      alert("Product Deleted successfully");
      setProducts((prevProducts) => prevProducts.filter(product => product.id !== id));
      console.log("Product deleted successfully:", data);
    } catch (error) {
      alert("Error deleting product");
      console.error("Error deleting product:", error);
    }
  };

  return (
    <>
      <div>
       

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
              
                <th scope="col" className="px-6 py-3">
                 Order ID
                </th>
              
                <th scope="col" className="px-6 py-3">
                  User Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Time
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Show</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {product.id}
                  </td>
            
                  <td className="px-6 py-4">{product.user_id}</td>
                  <td className="px-6 py-4">{product.created_date}</td>
                  <td className="px-6 py-4">{product.created_time}</td>
              
                  <td className="px-6 py-4 mr-4">
                    <a href={`/OrderDetails?id=${product.id}`} >
                      Show Details
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Orders;
