import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SideBar from './Components/sideBar';
import HomeIN from './home';
import Products from './Products';
import DrinkSale from './Products/DrinkSale';
import AddDrinkSales from './Products/AddDrinkSales';
import AddInDrink from './Products/AddInDrink';
import Sales from './Sales';
import AddInSale from './Sales/AddInSale';
import DrinkSaleProducts from './Products/DrinkSaleProducts';
import AddSale from './Sales/AddSale';
import SaleProducts from './Sales/SaleProducts';
import EditProduct from './Products/EditProduct';
import Orders from './Orders';
import Login from './Login';
import OrderDetails from './OrderDetails';
import EditImages from './Products/EditImages';

const Home = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login/>} />
      </Routes>
      <div className="flex">
        <SideBar className="z-40" />
        <div className="flex-1 p-4">
          <Routes>
            <Route path="/Addproduct" element={<HomeIN />} />
            <Route path='/editProduct' element={<EditProduct/>} />
            <Route path='/editProductImages' element={<EditImages />} />
            <Route path='/Orders' element={<Orders/>}/>
            <Route path='/OrderDetails' element={<OrderDetails/>} />
            <Route path='/home' element={<Products/>}  />
            <Route path='/DrinkSale' element={<DrinkSale/>} />
            <Route path='/DrinkSaleProducts' element={<DrinkSaleProducts />} />
            <Route path='/AddDrinkSales' element={<AddDrinkSales/>} />
            <Route path='/AddInDrink' element={<AddInDrink/>} />
            <Route path='/Sales' element={<Sales/>} />
            <Route path='/AddSale' element={<AddSale/>} />
            <Route path='/AddInSale' element={<AddInSale/>} />
            <Route path='/SaleProduct' element={<SaleProducts />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Home;
