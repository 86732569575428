import React, { useState, useEffect } from "react";

const DrinkSale = () => {
  const [products, setProducts] = useState([]);
  const [authorization,SetAuthorization]=useState('');


  useEffect(() => {

    let validate = sessionStorage.getItem('token');
if(!validate){
  window.location.href="/";
}if(validate){
  SetAuthorization(validate);
}


    const fetchData = async () => {
      try {
        const response = await fetch("https://abz.zuluevconsultancy.com/api/user/DrinkSale");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const Delete=async(id)=>{
    alert(id);

 try {
      const apiUrl = `https://abz.zuluevconsultancy.com/admin/DrinkSaleDelete`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
            "authorization":authorization,
        },
        body: JSON.stringify({ id}),
      });

      if (!response.ok) {
        throw new Error("Failed to add product");
      }
      const data = await response.json();
      alert("Product Deleted successfully");
      window.location.reload();
      console.log("Product added successfully:", data);
    } catch (error) {
      alert("Error Deleting product");
      console.error("Error adding product:", error);
      }

  }

  return (
    <>
      <div>
        <div>
          <a
            href="/AddDrinkSales"
            className="bg-gray-700 px-3 pb-2 pt-2 mb-2 text-white font-bold text-xl w-full"
          >
            Upload
          </a>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Image
                </th>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Started At
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
                <th scope="col" className="px-6 py-3">
                  Delete 
                </th>
              </tr>
            </thead>
           <tbody>
  {products.map((product, index) => (
    <tr
      key={index}
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <td>
        <img
          src={`data:image/png;base64,${product.image}`}
          alt=""
          className="h-12 w-12 ml-1 object-cover"
        />
      </td>
      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {product.name}
      </td>
      <td className="px-6 py-4">${product.price}</td>
      <td className="px-6 py-4 text-right">
        <a
          href={`/DrinkSaleProducts?drinkId=${product.id}`}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          <svg
            className="w-8 h-8 ml-2 text-blue-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M19.707 12.707l-6.5-6.5a1 1 0 0 0-1.414 1.414L16.586 12l-4.793 4.793a1 1 0 1 0 1.414 1.414l6.5-6.5a1 1 0 0 0 0-1.414z" />
          </svg>
        </a>
      </td>
      <td>
        
        <button onClick={() => { Delete(product.id); }}>
          <svg
            className="w-8 h-8 ml-6 text-blue-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M3 6l1 16.004A2.002 2.002 0 006.004 24h11.992A2.002 2.002 0 0021 22.004L22 6H3zm18-2V2a2 2 0 00-2-2H5a2 2 0 00-2 2v2H0v2h24V4h-3zM9 2h6v2H9V2zM8 10h2v10H8V10zm6 0h2v10h-2V10z" />
          </svg>
        </button>
      </td>
    </tr>
  ))}
</tbody>

          </table>
        </div>
      </div>
    </>
  );
};

export default DrinkSale;
